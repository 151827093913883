import HeadingCard from "../../cards/HeadingCard";

function ItpecHeading() {
  return (
    <>
      <HeadingCard
        isBackBtnShow={true}
        title="Information Technology Professionals  Examination Council (ITPEC)"
      />
    </>
  );
}

export default ItpecHeading;
