import {
  ProjectManagementHeading,
  ProjectManagementIntro,
  ProjectManagementQandA,
} from "../components";
import HelmetHeading from "../utils/HelmetHeading";

function ProjectManagement() {
  return (
    <>
      {" "}
      <HelmetHeading
        title="Project Management | Institude of Technology"
        description="Project management is the backbone of any successful business or organization. With our comprehensive Project Management Course, you'll gain the skills and knowledge to lead projects effectively, meet objectives, and exceed expectations. In today’s fast-paced world, managing a project effectively is more than just meeting deadlines. It requires mastering leadership, technical skills, and strategic thinking. Our course is designed to equip you with these essential skills to ensure your projects are well-planned, executed, and delivered successfully."
      />
      {/* <Helmet>
        <title>Project Management | Institude of Technology</title>
      </Helmet> */}
      <ProjectManagementHeading />
      <ProjectManagementIntro />
      <ProjectManagementQandA />
    </>
  );
}

export default ProjectManagement;
