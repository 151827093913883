import {
  HeroHeader,
  // OurPartnership,
  ProgrammeWeOffer,
  TeachingApproach,
  WhyChooseOIT,
  ProgrammeCarousel,
} from "../components";
import HelmetHeading from "../utils/HelmetHeading";

function Hero() {
  return (
    <>
      <HelmetHeading
        title="Home | Origin Institude of Technology"
        description="More than just a degree, Join with OIT"
      />
      <HeroHeader />
      <TeachingApproach />
      <WhyChooseOIT />
      <ProgrammeWeOffer />
      <ProgrammeCarousel />
      {/* <OurPartnership /> */}
    </>
  );
}

export default Hero;
