import { AboutHeading, VisionAndMission } from "../components";
import HelmetHeading from "../utils/HelmetHeading";
function AboutUs() {
  return (
    <>
      <HelmetHeading
        title="About | Origin Institude of Technology"
        description="Origin Institute of Technology (OIT), we shape the future of technology professionals by offering innovative, hands-on education that bridges the gap between theory and practical application. Whether you are taking your first step into the world of IT or enhancing your existing skills, OIT provides an unparalleled learning environment that ensures you achieve your career aspirations."
      />
      <AboutHeading />
      <VisionAndMission />
      {/* <Hierarchy /> */}
    </>
  );
}

export default AboutUs;
