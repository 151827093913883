import { Pdev, PdevHeading, Programs } from "../components";
import HelmetHeading from "../utils/HelmetHeading";

function ProfessionalDevelopment() {
  return (
    <>
      {" "}
      <HelmetHeading
        title="Professional Development | Origin Institude of Technology"
        description="Elevate your career with our Professional Development programs. Gain essential skills, enhance your expertise, and connect with industry leaders. Whether you’re seeking certification or advanced training, our tailored courses empower you to thrive in a competitive landscape. Join us today and take the next step in your professional journey!"
      />
      {/* <Helmet>
        <title>Professional Development | Origin Institude of Technology</title>
      </Helmet> */}
      <Pdev />
      <PdevHeading />
      <Programs />
    </>
  );
}

export default ProfessionalDevelopment;
