import { Link } from "react-router-dom";

function ProgrammeCard({ image, title, info, redirection }) {
  return (
    <>
      <div
        className="flex  flex-col rounded-lg"
        style={{
          border: "1px solid rgba(22, 43, 62, 0.20",
          minWidth: "290px",
          maxWidth: "300px",
          maxHeight: "330px",
          minHeight: "328px",
        }}>
        <img
          src={image}
          alt="title"
          className="object-fill min-h-40 max-h-40 w-auto"
        />
        <div
          style={{ height: "330px" }}
          className="flex justify-evenly flex-col">
          <h1
            className="font-semibold line-clamp-1 pt-3 pl-2 "
            style={{ fontSize: "16px" }}>
            {title}
          </h1>
          <p
            className="font-light text-justify max-w-[280px] h-24 text-wrap  p-3 s w-80 "
            style={{ fontSize: "14px" }}>
            {info}
          </p>
          <div className="w-full flex justify-end pr-3 pb-2">
            <Link
              to={`${redirection}`}
              className="text-xs text-right underline w-full mr-0 ml-auto"
              style={{
                textDecoration: "underlined",
                textAlign: "right",
                position: "relative",
                marginBottom: "0px",
                marginTop: "auto",
              }}>
              Details
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgrammeCard;
