import {
  SoftwareDevelopmentHeading,
  SoftwareDevelopmentIntro,
  SoftwareDevelopmentQandA,
} from "../components";
import HelmetHeading from "../utils/HelmetHeading";

function SoftwareDev() {
  return (
    <>
      {" "}
      <HelmetHeading
        title="Software Development | Institude of Technology"
        description="Software Development Fundamentals course is designed to provide you with a solid foundation in the core principles of programming and software development. Whether you’re a complete beginner or looking to strengthen your skills, this course covers everything from basic coding concepts to essential problem-solving techniques. Through hands-on projects, you’ll learn the fundamentals of programming languages, algorithms, data structures, and software design, preparing you to take your first steps toward a rewarding career in technology. At OIT, we emphasize real-world application, ensuring you’re ready to tackle complex challenges as you continue your journey in software development."
      />
      {/* <Helmet>
        <title>Software Development | Origin Institude of Technology</title>
      </Helmet> */}
      <SoftwareDevelopmentHeading />
      <SoftwareDevelopmentIntro />
      <SoftwareDevelopmentQandA />
    </>
  );
}

export default SoftwareDev;
