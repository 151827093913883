import { NewsArticles, NewsHeader } from "../components";
import HelmetHeading from "../utils/HelmetHeading";

function NewsAndArticles() {
  return (
    <>
      <HelmetHeading
        title="News and Articles | Origin Institude of Technology"
        description="Stay updated with the latest happenings at Origin Institute of Technology! Explore our recent events, student achievements, and industry insights. Join us in celebrating innovation and growth as we empower future tech leaders. Discover how we’re shaping the IT landscape and fostering a vibrant learning community."
      />
      {/* <Helmet>
        <title>News and Articles | Origin Institude of Technology</title>
      </Helmet> */}
      <NewsHeader />
      <NewsArticles />
    </>
  );
}

export default NewsAndArticles;
