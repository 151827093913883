import { DiplomaHeading, DiplomaMidSec, DiplomaPrograms } from "../components"; 
import HelmetHeading from "../utils/HelmetHeading";

function Diploma() {
  return (
    <>
      <HelmetHeading
        title="Diploma | Origin Institude of Technology"
        description="Unlock your potential with our Diploma program! Gain essential skills and knowledge to excel in your career. Our hands-on training, expert instructors, and industry connections empower you to thrive in today's competitive landscape. Enroll now to start your journey toward success!"
      />
      {/* <Helmet>
        <title>Diploma | Origin Institude of Technology</title>
      </Helmet> */}
      <DiplomaHeading />
      <DiplomaMidSec />
      <DiplomaPrograms />
    </>
  );
}

export default Diploma;
