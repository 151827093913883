import HelmetHeading from "../utils/HelmetHeading";
import ComingSoon from "./ComingSoon";

function AdvancedCloudBootcamp() {
  return (
    <>
      <HelmetHeading
        title="Advanced Cloud Bootcamp | Origin Institude of Technology"
        description=""
      />
      <ComingSoon />
    </>
  );
}

export default AdvancedCloudBootcamp;
