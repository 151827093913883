import { useNavigate } from "react-router-dom";
import "./index.css";
import { HiOutlineChevronLeft } from "react-icons/hi";

function HeadingCard({ title, isBackBtnShow }) {
  const navigate = useNavigate();
  return (
    <div
      className="w-full px-10 flex justify-center items-end"
      style={{
        height: "294px",
        backgroundColor: "var(--Dark-Black, #162B3E)",
      }}>
      <div
        className=" flex justify-center items-center"
        style={{
          height: "237px",
          width: "870px",
        }}>
        <div
          className="flex flex-col items-center justify-center"
          style={{
            height: "237px",
          }}>
          {isBackBtnShow && (
            <button
              onClick={() => navigate(-1)}
              className=" absolute left-10 top-44">
              <HiOutlineChevronLeft color="white" size={35} />
            </button>
          )}
          <h1
            className="text-sm sm:text-2xl pb-16 md:text-3xl lg:text-4xl xl:text-5xl text-white whitespace-nowrap overflow-visible"
            style={{
              letterSpacing: "3.44px",
              textShadow: "0px 8px 30px #00A6FE",
              fontWeight: "500",
            }}
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line
              __html: title.replace(/  /g, "&nbsp;<br/>&nbsp;"),
            }}></h1>
        </div>
      </div>
    </div>
  );
}

export default HeadingCard;
