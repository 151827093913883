import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";
import { homeHeaderImg, heroHeaderImg2, heroHeaderImg3 } from "../../assets";
import "./index.css";

SwiperCore.use([Autoplay, Pagination]);

function HeroHeader() {
  const backgrounds = [homeHeaderImg, heroHeaderImg2, heroHeaderImg3]; // Add more images as needed

  return (
    <div style={{ maxHeight: "404px", position: "relative" }}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        className="mySwiper">
        {backgrounds.map((bg, index) => (
          <SwiperSlide key={index}>
            <div
              style={{
                height: "404px",
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}></div>
          </SwiperSlide>
        ))}
        <div className="swiper-pagination"></div>
      </Swiper>

      <div
        className="w-fit md:pt-14 p-12 h-40 shadow-lg shadow-blue-400"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.38)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 10,
        }}>
        <h1 className="sm:text-2xl text-center md:text-4xl font-semibold">
          More than just a degree, Join with OIT
        </h1>
      </div>
    </div>
  );
}

export default HeroHeader;
