import {
  CloudComputingHeading,
  CloudComputingIntro,
  CloudComputingQandA,
} from "../components";
import HelmetHeading from "../utils/HelmetHeading";

function CloudComputing() {
  return (
    <>
      <HelmetHeading
        title="Cloud Computing | Origin Institude of Technology"
        description="Cloud Computing Fundamentals course offers a comprehensive introduction to the core concepts and technologies behind cloud computing. Whether you're a beginner or an IT professional looking to expand your skill set, this course provides a strong foundation in cloud services, infrastructure, and deployment models. You’ll learn about the benefits of cloud computing, the differences between public, private, and hybrid clouds, and how to leverage leading cloud platforms like AWS, Azure, and Google Cloud to build scalable, flexible, and secure applications."
      />
      {/* <Helmet>
        <title>Cloud Computing | Origin Institude of Technology</title>
      </Helmet> */}
      <CloudComputingHeading />
      <CloudComputingIntro />
      <CloudComputingQandA />
    </>
  );
}

export default CloudComputing;
