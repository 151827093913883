import { IoChevronBack } from "react-icons/io5";
import {
  footerBg,
  articleOneBg,
  articleTwoBg,
  articleThreeBg,
} from "../assets";
import { Link, useParams } from "react-router-dom";
import React from "react";
import { GiOpenBook } from "react-icons/gi";
import HelmetHeading from "../utils/HelmetHeading";

const recentPosts = [
  {
    title: "OIT ကို ဘာကြောင့် ရွေးချယ်သင့်တာလဲ",
    details:
      "IT နည်းပညာရပ်ကို Beginner ကနေ Professional Level အထိ သင်ယူပီးတော့ IT Professional တယောက်အနေနဲ့ လုပ်ငန်းခွင်ထဲကို ဆက်လက်လျောက်လှမ်းသွားဖို့ ရည်ရွယ် ထားတယ်ဆိုရင်တော့ ....",

    backgroundImage: articleOneBg,
  },
  {
    title:
      "Digital နည်းပညာခေတ်မှာ မည်သည့်လုပ်ငန်းအတွက်မဆို အသုံးဝင်လာမယ့် IT ပညာရပ်များ",
    details:
      "Digital နည်းပညာတွေ ဖွံ့ဖြိုးတိုးတက်လာတာနဲ့အမျှ IT နည်းပညာ လိုအပ်ချက် နဲ့ IT ကျွမ်းကျင်ပညာရှင်တွေက မည်သည့်နေရာဒေသမှာမဆို  လိုအပ်ချက်တွေရှိလာပါပြီ....",
    backgroundImage: articleTwoBg,
  },
  {
    title:
      "IT Professional တစ်ယောက်ဖြစ်ဖို့ အိပ်မက်တွေကို OITနဲ့အတူ အကောင်အထည်ဖော်လိုက်ပါ",
    details:
      "Digital ခေတ်တစ်ခေတ် အရှိန်အဟုန်ကောင်းလာတာကြောင့် နည်းပညာတွေက အဘက်ဘက်ကနေများပြားစွာ တိုးတက်လာနေပါတယ်။ အဲဒီနည်းပညာတွေထဲမှာ (IT) ပညာရပ်ဟာ....",
    backgroundImage: articleThreeBg,
  },
];

function RecentPosts() {
  return (
    <div className="grid grid-cols-2 gap-2">
      {recentPosts.map((post, index) => (
        <Link key={index} to={`/news-and-articles/news/${index}`}>
          <div className="flex items-center justify-center p-1 flex-col border-blue-950 rounded-md gap-3 border md:max-w-[190px] light-blue-shadow overflow-hidden mb-8">
            <img
              src={post.backgroundImage}
              alt="post"
              className="w-full md:w-80 h-24 rounded-md object-cover object-top"
            />
            <p className="text-black text-xs text-left  line-clamp-1 ">
              {post.title}
            </p>
            <p className="dark-text text-xs text-left  line-clamp-4 ">
              {post.details}
            </p>
            <GiOpenBook className="w-5 h-5 text-blue-950 self-end" />
          </div>
        </Link>
      ))}
    </div>
  );
}

function SelectedArticle({ newsData }) {
  const { index } = useParams(); // Get the article index from the URL
  const articleIndex = parseInt(index); // Convert the index to an integer
  const article = newsData[articleIndex]; // Get the article directly by index

  if (!article) {
    return <div>Article not found!</div>;
  }

  // Replace double spaces with line breaks
  // eslint-disable-next-line
  const formattedDetails = article.details.replace(/  /g, "<br><br>");

  return (
    <>
      <HelmetHeading
        title={`${article.title} | Origin Institude of Technology`}
        description={article.details}
        imageUrl={article.backgroundImage}
      />
      {/* <Helmet>
        <title>{article.title}</title>
        <meta property="og:image" content={article.backgroundImage} />
        <meta name="twitter:image" content={article.backgroundImage} />
      </Helmet> */}
      <div
        style={{
          backgroundImage: `url(${article.backgroundImage})`,
          minWidth: "100vw",
          minHeight: "300px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 24%",
        }}>
        <Link to="/news-and-articles">
          <p className="text-left ml-10 pt-5 text-white">
            <IoChevronBack size={25} />
          </p>
        </Link>
        {/* <h1
          className="text-center text-5xl text-white items-center mt-24  text-bold"
          style={{ textShadow: "0px 4px 4px rgba(0, 166, 254, 0.25)" }}>
          {article.title}
        </h1> */}
      </div>
      <div className="flex flex-col  md:flex-row justify-center items-start mb-10 gap-10 mt-10">
        <div style={{ maxWidth: "867px" }}>
          <h1
            className="text-left text-3xl tracking-wide mb-7"
            style={{ lineHeight: "45px" }}>
            {article.title}
          </h1>
          <p
            className="text-justify"
            dangerouslySetInnerHTML={{ __html: formattedDetails }}></p>
        </div>
        <div className="flex md:max-w-96 w-full min-w-96 pb-5 flex-col ">
          <div
            className="w-full text-black h-11 mb-1 light-blue-shadow font-bold items-center pt-1 flex justify-evenly"
            style={{
              backgroundImage: `url("${footerBg}")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            Recent Posts
          </div>
          <RecentPosts />
        </div>
      </div>
    </>
  );
}

export default SelectedArticle;
