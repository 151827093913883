import { footerLine, siteLogo, footerBg } from "../assets";
import { FaLinkedin, FaTelegramPlane, FaTiktok } from "react-icons/fa";
import { FaViber } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa6";

function Footer() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url("${footerBg}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}>
        <div className="footer-container flex flex-col md:flex-row  md:justify-between md:items-center pb-5 text-white">
          <div className="flex flex-col ml-5 md:ml-24">
            <div>
              <img
                src={siteLogo}
                style={{ minWidth: "115px", maxWidth: "150px" }}
                alt="Logo"
              />
            </div>
            <div>
              <p
                className="mb-5 text-lg text-justify w-96 font-semibold"
                style={{ letterSpacing: "0.16px" }}>
                Ready to take the first step toward a rewarding career in IT?
                Enroll today and become part of a community that’s driving
                technological innovation. At Origin Institute of Technology,
                your future in IT starts here!
              </p>
            </div>
            <div className="flex flex-row bg-white text-black pl-3 pr-3 p-1 rounded-3xl w-52">
              <a
                href="https://www.facebook.com/origininstituteoftechnology?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer">
                <FaFacebookF className="h-6 ml-3 w-auto m-1 text-blue-950" />
              </a>
              <a
                href="https://www.tiktok.com/@origin.institute?_t=8q7zSqXU0nA&_r=1"
                target="_blank"
                rel="noopener noreferrer">
                <FaTiktok className="w-auto m-1 h-6 text-blue-950" />
              </a>
              <a
                href="https://t.me/oirgininstituteoftechnology"
                target="_blank"
                rel="noopener noreferrer">
                <FaTelegramPlane className="h-7 w-auto m-1 text-blue-950" />
              </a>
              <a
                href="https://invite.viber.com/?g2=AQAjEp3ikUbbwlOPhi5PHnXzfA%2BKIsLjEGBf8%2BU8jsra8OCHkKqRHsaNHwcYPosc"
                target="_blank"
                rel="noopener noreferrer">
                <FaViber className="h-7 w-auto m-1 text-blue-950" />
              </a>
              <a
                href="https://www.linkedin.com/company/origin-institute-of-technology"
                target="_blank"
                rel="noopener noreferrer">
                <FaLinkedin className="h-7 w-auto mr-12 m-1 text-blue-950" />
              </a>
            </div>
          </div>
          <div className="flex mt-8 flex-row gap-5">
            <div>
              <img src={footerLine} alt="line" className="hidden md:block" />
            </div>
            <div>
              <h3 className="text-left font-bold text-lg mb-3 mt-5">
                Quick Links
              </h3>
              <p className="flex flex-row gap-1 font-semibold items-center">
                <div className="w-2 h-2 bg-white rounded-full mr-2"></div>
                <Link to="/diploma">
                  <p
                    style={{
                      textDecoration: "underline",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}>
                    Diploma
                  </p>
                </Link>
              </p>
              <p
                className="flex flex-row gap-1 font-semibold items-center"
                style={{
                  textDecoration: "underline",
                  fontSize: "16px",
                  fontWeight: "400",
                }}>
                {" "}
                <div className="w-2 h-2 bg-white rounded-full mr-2"></div>
                <Link to="/professional-development">
                  <p>Professional Development</p>
                </Link>
              </p>
              <p
                className="flex flex-row gap-1 font-semibold items-center"
                style={{
                  textDecoration: "underline",
                  fontSize: "16px",
                  fontWeight: "400",
                }}>
                {" "}
                <div className="w-2 h-2 bg-white rounded-full mr-2"></div>
                <p> Others</p>
              </p>
            </div>
          </div>
          <div className="flex flex-row mt-8 gap-5">
            <div>
              <img src={footerLine} alt="line" className="hidden md:block" />
            </div>
            <div className="flex flex-col gap-3 text-start">
              <h3 className="font-bold text-lg mb-3 mt-5">Contact Us</h3>
              <p className="w-80">
                No (5-D), 99 Condo, Dhammazedi Road, Kamaryut Township, Yangon,
                Myanmar
              </p>
              <p>(+95) 9752377778</p>
              <p>contact@oit.com.mm</p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-white dark-background text-center p-1">
        Copyright 2024. All right reserved by OIT
      </div>
    </>
  );
}

export default Footer;
