import { itpecProgram } from "../../../assets";
import { ProgramIntroCard } from "../../cards";

function ItpecBody() {
  const itpecDetails = [
    "Information Technology Professionals Examination Council (ITPEC) is an internationally recognized certification body that plays a pivotal role in standardizing IT skills across Asia. ITPEC provides a comprehensive examination framework designed to assess and certify the competency of IT professionals in various domains such as programming, database management, network engineering, and IT project management. This certification is highly regarded in countries like Japan, Myanmar, Thailand, and Vietnam, ensuring that certified individuals meet the industry standards required for professional growth. By earning an ITPEC certification, you demonstrate a strong foundation in IT knowledge, opening doors to career advancement and global opportunities in the tech industry.",
  ];
  return (
    <>
      <ProgramIntroCard
        title="Introduction to (ITPEC)"
        details={itpecDetails}
        programImg={itpecProgram}
        downloadLink="#"
      />
    </>
  );
}
export default ItpecBody;
