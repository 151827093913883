import { ccHeaderBg } from "../../../assets";
import HeadingCard from "../../cards/HeadingCard";

function ProjectManagementHeading() {
  return (
    <>
      <HeadingCard
        isBackBtnShow={true}
        title="Project Management Course"
        backgroundImage={ccHeaderBg}
      />
    </>
  );
}

export default ProjectManagementHeading;
