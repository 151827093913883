import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import defaultImageUrl from "../assets/grayLogo.png";

const HelmetHeading = ({ title, description, imageUrl = defaultImageUrl }) => {
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}`;

  const truncatedTitle = title.length > 30 ? title.slice(0, 30) + "..." : title;
  const truncatedDescription =
    description.length > 400 ? description.slice(0, 300) + "..." : description;

  return (
    <Helmet>
      <meta name="description" content={truncatedDescription} />
      <meta property="og:title" content={truncatedTitle} />
      <meta property="og:description" content={truncatedDescription} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={truncatedTitle} />
      <meta name="twitter:description" content={truncatedDescription} />
      <meta name="twitter:image" content={imageUrl} />
      <title>{truncatedTitle}</title>
    </Helmet>
  );
};

export default HelmetHeading;
