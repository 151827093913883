import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";
import {
  advancedCc,
  cloudComputing,
  itpecProgram,
  softwareDevFound,
  softwareTesting,
  uiUx,
} from "../../assets";
import ProgrammeCard from "../cards/ProgrammeCard";
import "./index.css";

SwiperCore.use([Autoplay, Navigation]);

function ProgrammeCarousel() {
  const programmes = [
    {
      title: "ITPEC",
      info: "Master IT skills and ace the ITPEC exam with expert-led courses, hands-on practice, and industry-recognized certification.",
      image: itpecProgram,
      programInfo: "/itpec",
    },
    {
      title: "Software Development Foundations",
      info: "The essentials of software development. Learn coding, project management, agile practices, and more with hands-on training.",
      image: softwareDevFound,
      programInfo: "/software-development",
    },
    {
      title: "Cloud Computing Fundamental",
      info: "Master the basics of cloud computing and gain essential skills to leverage cloud technology for scalable, efficient solutions.",
      image: cloudComputing,
      programInfo: "/cloud-computing",
    },
    {
      title: "Advanced Cloud Computing Bootcamp",
      info: "Master cloud infrastructure, deployment, and security in our Advanced Cloud Computing Bootcamp. Elevate your cloud expertise today!",
      image: advancedCc,
      programInfo: "/coming-soon",
    },
    {
      title: "Professional in UI/UX Design",
      info: "Master UI/UX design with expert-led classes. Learn user-centered design, wireframing, prototyping, and create stunning digital experiences.",
      image: uiUx,
      programInfo: "/coming-soon",
    },
    {
      title: "Professional in Software Testing",
      info: "Master the art of Software Testing. Gain in-demand skills, ensure quality, and accelerate your career in tech today!",
      image: softwareTesting,
      programInfo: "/coming-soon",
    },
  ];

  return (
    <>
      <h1 className="mn-2 text-lg text-center font-semibold">
        Programme We Offer
      </h1>
      <p className="mb-4 text-center">
        At OIT, we offer a wide range of IT courses designed to help you build
        foundational skills or advance your existing knowledge. Our offerings
        include:
      </p>
      <div
        className="programme-container md:p-16 p-5"
        style={{
          position: "relative",
          overflow: "hidden",
        }}>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          loop={true}
          slidesPerView={"auto"}
          navigation={true}
          className="mySwiper ">
          {programmes.map((program, index) => (
            <SwiperSlide
              key={index}
              style={{ width: "300px" }}
              className="min-w-72">
              <ProgrammeCard
                title={program.title}
                image={program.image}
                info={program.info}
                redirection={program.programInfo}
                key={index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default ProgrammeCarousel;
