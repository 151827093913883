import { ccHeaderBg } from "../../../assets";
import HeadingCard from "../../cards/HeadingCard";

function CloudComputingHeading() {
  return (
    <>
      <HeadingCard
        isBackBtnShow={true}
        title="Cloud Computing Fundamental"
        backgroundImage={ccHeaderBg}
      />
    </>
  );
}

export default CloudComputingHeading;
