import React, { useState } from "react";
import { ProgramQandA } from "../components/cards";

function QandAContainer({ courseDetails }) {
  const [openIndex, setOpenIndex] = useState(0); // First question open by default
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle the index
  };

  return (
    <>
      <div className="flex flex-col justify-center md:pr-32">
        {courseDetails.map((detail, index) => (
          <ProgramQandA
            key={index}
            question={detail.title}
            answers={detail.details}
            isCourseOutline={detail.isCourseOutline}
            isOpen={openIndex === index} // Pass whether this question is open
            onToggle={() => handleToggle(index)} // Pass the toggle function
          />
        ))}
      </div>
    </>
  );
}

export default QandAContainer;
