import QandAContainer from "../../../layout/QandAContainer";
import React from "react";

function ProjectManagementQandA() {
  const courseDetails = [
    {
      title: "Course Outline",
      details: [
        "Module 1: Introduction to Project Management",
        "Module 2: Develop a Project Plan (Problem Identification and Project Initiation)",
        "Module 3: Project Planning and Building a Project Schedule",
        "Module 4: Overview of Agile Project Management",
        "Module 5: Project Implementation",
        "Module 6: Working with Project Team",
        "Module 7: Project Monitoring, Controlling the process, Performance Evaluation, Reporting",
        "Module 8: Project Closing",
      ],
      isCourseOutline: true,
    },
    {
      title: "Entry Requirements",
      details: [
        "• No prior experience is required. We start from the basics.",
        "• You'll need access to Microsoft Office (Word, PowerPoint, Excel) to download and practice with the supplemental course materials.",
      ],
      isCourseOutline: false,
    },
    {
      title: "Suitable Position",
      details: [
        "Project Managers",
        "Operations Managers",
        "Programme Managers",
        "Project Engineers",
        "IT Managers",
        "Team Leaders / Team Head",
        "Project Planners",
        "NGO/INGO",
      ],
      isCourseOutline: false,
    },
    {
      title: "What you'll learn",
      details: [
        "Gain the project management knowledge and skills necessary to manage an entire project",
        "Perform a complete case study of a project from the beginning to the end, observing real-life project scenarios",
        "Understand the project manager role and acquire the skills needed to become successful in the profession",
        "Study Waterfall and Agile project management, performed with Scrum",
        "Learn the fundamental theory and best practices of project management",
      ],
      isCourseOutline: false,
    },
  ];
  return (
    <>
      <QandAContainer courseDetails={courseDetails} />
    </>
  );
}

export default ProjectManagementQandA;
