import { pDevHeaderBg } from "../../assets";
import HeadingCard from "../cards/HeadingCard";
import "./index.css";

function PdevHeading() {
  return (
    <HeadingCard
      isBackBtnShow={false}
      title=" Professional Development"
      backgroundImage={pDevHeaderBg}
    />
  );
}

export default PdevHeading;
