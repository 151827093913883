import { articleOneBg, articleThreeBg, articleTwoBg } from "../../assets";
import NewsCard from "../cards/NewsCard";

function NewsArticles() {
  const newsData = [
    {
      title: "OIT ကို ဘာကြောင့် ရွေးချယ်သင့်တာလဲ",
      details:
        "IT နည်းပညာရပ်ကို Beginner ကနေ Professional Level အထိ သင်ယူပီးတော့ IT Professional တယောက်အနေနဲ့ လုပ်ငန်းခွင်ထဲကို ဆက်လက်လျောက်လှမ်းသွားဖို့ ရည်ရွယ် ထားတယ်ဆိုရင်တော့ ....",

      backgroundImage: articleOneBg,
    },
    {
      title:
        "Digital နည်းပညာခေတ်မှာ မည်သည့်လုပ်ငန်းအတွက်မဆို အသုံးဝင်လာမယ့် IT ပညာရပ်များ",
      details:
        "Digital နည်းပညာတွေ ဖွံ့ဖြိုးတိုးတက်လာတာနဲ့အမျှ IT နည်းပညာ လိုအပ်ချက် နဲ့ IT ကျွမ်းကျင်ပညာရှင်တွေက မည်သည့်နေရာဒေသမှာမဆို  လိုအပ်ချက်တွေရှိလာပါပြီ....",
      backgroundImage: articleTwoBg,
    },
    {
      title:
        "IT Professional တစ်ယောက်ဖြစ်ဖို့ အိပ်မက်တွေကို OITနဲ့အတူ အကောင်အထည်ဖော်လိုက်ပါ",
      details:
        "Digital ခေတ်တစ်ခေတ် အရှိန်အဟုန်ကောင်းလာတာကြောင့် နည်းပညာတွေက အဘက်ဘက်ကနေများပြားစွာ တိုးတက်လာနေပါတယ်။ အဲဒီနည်းပညာတွေထဲမှာ (IT) ပညာရပ်ဟာ....",
      backgroundImage: articleThreeBg,
    },
  ];

  return (
    <>
      <div className="flex flex-wrap pt-10 gap-4 justify-start xl:justify-center items-center m-10">
        {newsData.map((article, index) => (
          <>
            <NewsCard
              title={article.title}
              details={article.details}
              redirect={index}
              backgroundImage={article.backgroundImage}
              currentIndex={index}
            />
          </>
        ))}
      </div>
    </>
  );
}

export default NewsArticles;
