import React, { useState } from "react";
import { faqBg, grayLogo } from "../assets";
import FaQCard from "../components/cards/FaQCard";
import HelmetHeading from "../utils/HelmetHeading";

function FaQuestions() {
  const [openIndex, setOpenIndex] = useState(0); // First question open by default

  const faqData = [
    {
      question: "What courses does OIT offer?",
      answer:
        "OIT offers a range of IT courses, including Diploma and Professional Courses. You can check it out in our program details.",
    },
    {
      question: "What is the duration of the courses?",
      answer:
        "Course durations vary; typically, short courses last from a few weeks to a couple of months, while boot camps may last several weeks, depending on the curriculum.",
    },
    {
      question: "What are the prerequisites for enrolling in courses?",
      answer:
        "Prerequisites vary by course. Generally, a basic understanding of computers and the internet is beneficial. Specific courses may have additional requirements.",
    },
    {
      question: "Do you provide certificates upon course completion?",
      answer:
        "Yes, OIT provides certificates of completion for all courses, which can enhance your resume and validate your skills.",
    },
    {
      question: "Are there any job placement services?",
      answer:
        "OIT collaborates with local businesses and organizations to assist students in finding job opportunities after completing their courses.",
    },
    {
      question: "Can I attend classes online?",
      answer:
        "Yes, OIT offers both in-person and online classes to accommodate different learning preferences. Check the specific course details for availability.",
    },
    {
      question: "How can I register for a course?",
      answer:
        "You can register for courses through our website. Simply select your desired course and follow the registration instructions.",
    },
    {
      question: "What payment options are available?",
      answer:
        "OIT accepts various payment methods, including Mobile Payment, Banking transfers, and Cash. Installment plans may also be available for certain courses.",
    },
    {
      question: "Who are the instructors at OIT?",
      answer:
        "Our instructors are industry professionals with extensive experience in their fields, providing practical knowledge and real-world insights.",
    },
    {
      question: "How can I contact OIT for further inquiries?",
      answer:
        "You can reach us via our website contact form, email, or by calling our office. We're here to help!",
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle the selected question
  };

  return (
    <>
      <HelmetHeading
        title="FAQs  | Origin Institude of Technology"
        description="Have questions? We’ve got answers! Explore our comprehensive FAQ section to find solutions, clarify doubts, and enhance your experience with our services. Your queries are our priority!"
      />
      {/* <Helmet>
        <title>FAQs | Origin Institude of Technology</title>
      </Helmet> */}
      <div>
        <img src={faqBg} alt="FAQ" className="w-full h-full" />
      </div>

      <div className="flex flex-col justify-center items-center pb-10 gap-5 dark-background text-white">
        <div className="flex flex-row justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="67"
            height="7"
            viewBox="0 0 67 7"
            fill="none">
            <path
              d="M61.3333 3.5C61.3333 4.97276 62.5272 6.16667 64 6.16667C65.4728 6.16667 66.6667 4.97276 66.6667 3.5C66.6667 2.02724 65.4728 0.833333 64 0.833333C62.5272 0.833333 61.3333 2.02724 61.3333 3.5ZM0 4H64V3H0V4Z"
              fill="white"
            />
          </svg>
          <img
            src={grayLogo}
            alt="Institute Logo"
            style={{ width: "100px", height: "100px" }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="67"
            height="7"
            viewBox="0 0 67 7"
            fill="none">
            <path
              d="M0.333333 3.5C0.333333 4.97276 1.52724 6.16667 3 6.16667C4.47276 6.16667 5.66667 4.97276 5.66667 3.5C5.66667 2.02724 4.47276 0.833333 3 0.833333C1.52724 0.833333 0.333333 2.02724 0.333333 3.5ZM3 4H67V3H3V4Z"
              fill="white"
            />
          </svg>
        </div>
        <h1 className="text-center mb-5">Frequently Asked Questions</h1>
        <p className="text-center mb-2 max-w-[1200px] h-auto">
          Have questions? We’ve got answers! Explore our comprehensive FAQ
          section to find solutions, clarify doubts, and enhance your experience
          with our services. Your queries are our priority!
        </p>
        {faqData.map((data, index) => (
          <FaQCard
            key={index}
            question={data.question}
            answers={data.answer}
            isOpen={openIndex === index} // Pass whether the question is open
            onToggle={() => handleToggle(index)} // Pass the toggle function
          />
        ))}
      </div>
    </>
  );
}

export default FaQuestions;
